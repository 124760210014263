<template>
  <div class="announcement">
    <loading-screen :is-loading="isDeleting"></loading-screen>
    <div class="announcement-top">
      <!-- Loading -->
      <div v-if="isLoading" class="text-center loading-wrapper">
        <a-spin />
      </div>
      <!-- / Loading -->

      <!-- No announcement -->
      <div v-if="!isLoading && !announcement" class="no-announcement-wrapper">
        <a-alert
          type="error"
          message="Error loading announcement, please try again."
        />
      </div>
      <!-- / No announcement -->

      <!-- Announcement loaded -->
      <div v-if="!isLoading && announcement" class="announcement-inner">
        <a-layout id="announcement-show-inner-layout">
          <a-layout :style="{ background: '#f7f8fa' }">
            <a-layout-content :style="{ padding: '20px 30px' }">
              <div class="announcement-wrapper">
                <div class="top">
                  <div class="page-header">
                    <h1 class="page-title">
                      <a-button
                        type="default"
                        style="display: inline; margin-right: 15px"
                        v-if="adminMode"
                        @click.prevent="goBack"
                        icon="left"
                      ></a-button>

                      <admin-org-indicator v-if="adminMode"></admin-org-indicator>

                      {{ displayName }}

                      <pin-button v-if="!adminMode" style="margin-left: 10px;" :guid="announcement.id"></pin-button>
                    </h1>
                    <announcement-tags
                      :announcement="announcement"
                    ></announcement-tags>
                    <div class="actions">
                      <a-popconfirm
                        v-if="canDeleteAnnouncement"
                        title="Are you sure?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="attemptDelete"
                      >
                        <a-button
                          class="button-margin-left btn-rounded"
                          icon="delete"
                          size="large"
                          type="danger"
                          >Delete</a-button
                        >
                      </a-popconfirm>
                    </div>
                  </div>
                </div>
                <div class="middle">
                  <div class="announcement-description">
                    {{ announcementDescription }}
                  </div>

                  <!-- Announcement Cards -->
                  <div
                    class="announcement-response-cards"
                    v-if="
                      (myGroupMember && myGroupMember.isResponder) || amIAnAdmin
                    "
                  >
                    <h2>All Responses</h2>

                    <announcement-response-card
                      v-for="(responseGroup, i) in responses"
                      :key="getAnnouncementGroupKey(i)"
                      :response-group="responseGroup"
                      :tenant-id="tenantId"
                      :announcement-id="announcement.id"
                      :org-id="announcement.ownerId"
                    ></announcement-response-card>
                  </div>
                  <!-- / Announcement Cards -->
                </div>
              </div>
            </a-layout-content>
          </a-layout>
        </a-layout>
      </div>
      <!-- / Announcement loaded -->
    </div>
    <div class="announcement-bottom" v-if="myGroupMember">
      <!-- Respond -->
      <div class="responder-wrapper">
        <h2>
          {{
            haveIResponded
              ? "You responded " + myChoice["name"]
              : "You have not responded yet"
          }}
        </h2>
        <div class="response-buttons">
          <!-- {{ choices }} -->
          <announcement-choice-button
            v-for="(choice, i) in choices"
            :key="getAnnouncementChoiceButtonKey(i)"
            :choice="choice"
            :is-selected="isChoiceSelected(choice)"
            :announcement-guidance-pack-id="announcementGuidancePackId"
            :announcement-content-id="announcementContentId"

            :tenant-id="tenantId"
            :announcement="announcement"
            :my-presence-id="myPresenceId"
          >
          </announcement-choice-button>
        </div>
      </div>
      <!-- / Respond -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import eventBus from "../../event-bus";
import AnnouncementChoiceButton from "./Show/AnnouncementChoiceButton.vue";
import AnnouncementResponseCard from "./Show/AnnouncementResponseCard.vue";
import UpdatesLastReadPointer from "../../mixins/UpdatesLastReadPointer";
import AnnouncementTags from "../../components/Announcements/AnnouncementTags.vue";
import LoadingScreen from "../../components/LoadingScreen.vue";
import announcementsApi from "../../api/announcements";
const _ = require("lodash");
import PinButton from "../../components/PinButton.vue";
export default {
  name: "Show",
  components: {
    AnnouncementResponseCard,
    AnnouncementChoiceButton,
    AnnouncementTags,
    LoadingScreen,
    PinButton
  },
  data() {
    return {
      updateLastReadTries: 0,
      lastReadUpdater: null,
      justUpdatedMyLastReadPointer: false,
      isDeleting: false,
    };
  },
  mixins: [UpdatesLastReadPointer],
  watch: {
    announcement(newVal, oldVal) {
      if (newVal) {
        let vm = this;

        // Only reload timeline if
        // a) No group loaded before OR
        // b) We're loading a different group
        if (!oldVal || oldVal.id !== newVal.id) {
          // Load timeline
          this.loadTimeline({
            model: "announcement",
            tenantId: this.tenantId,
            guid: newVal.id,
            ownerType: 3,
            ownerId: newVal.id,
            organisationId: newVal.ownerId,
            behaviour: 0,
          }).then(() => {
            if (!(this.timeline && this.timeline.length)) {
              window.setTimeout(() => {
                this.loadTimeline({
                  model: "announcement",
                  tenantId: this.tenantId,
                  guid: newVal.id,
                  ownerType: 3,
                  ownerId: newVal.id,
                  organisationId: newVal.ownerId,
                  behaviour: 0,
                });
              }, 1000);
            }
          });
          vm.startLastReadUpdater();
          if (this.adminMode) {
            vm.subscribeIfAdminMode();
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters("announcementViewer", {
      announcement: "announcement",
      isLoading: "isLoading",
      myPresenceId: "myPresenceId",
      responses: "responses",
      haveIResponded: "haveIResponded",
      myChoice: "myChoice",
      choices: "choices",
      myGroupMember: "myGroupMember",
      adminMode: "adminMode",
    }),

    ...mapGetters("timeline", {
      timeline: "timeline",
    }),

    ...mapGetters("missionControl", {
      organisationsIAmAnAdminFor: "organisationsIAmAnAdminFor",
    }),

    amIAnAdmin() {
      let org = _.find(this.organisationsIAmAnAdminFor, {
        id: this.announcement.ownerId,
      });
      return !!org;
    },

    canDeleteAnnouncement() {
      let org = _.find(this.organisationsIAmAnAdminFor, {
        id: this.announcement.ownerId,
      });
      return !!org;
    },

    announcementId() {
      return this.$route.params.id;
    },

    announcementDescription() {
      if (this.timeline && this.timeline.length) {
        let firstElement = this.timeline[0];
        return firstElement["content"]["$v"]["text"];
      }
      return "No description provided";
    },

    announcementGuidancePackId() {
      if (this.timeline && this.timeline.length) {
        let firstElement = this.timeline[0];
        if (
          firstElement["content"]["$t"] ==
          "Soteria.Models.GuidanceChatEntry, Soteria.Models"
        ) {
          return firstElement["content"]["$v"]["guidancePackId"];
        }
      }
      return null;
    },

     announcementContentId() {
      if (this.timeline && this.timeline.length) {
        let firstElement = this.timeline[0];
        if (
          firstElement["content"]["$t"] ==
          "Soteria.Models.GuidanceChatEntry, Soteria.Models"
        ) {
          return firstElement["content"]["$v"]["contentId"];
        }
      }
      return null;
    },

    tenantId() {
      return this.$route.params.tenantId;
    },

    displayName() {
      if (
        this.announcement.displayName &&
        this.announcement.displayName.length
      ) {
        return this.announcement.displayName;
      }
      if (this.isLoading) {
        return "Loading";
      }
      return "Unknown";
    },
  },
  methods: {
    ...mapActions("announcementViewer", {
      loadAnnouncement: "loadAnnouncement",
      enableAdminMode: "enableAdminMode",
      disableAdminMode: "disableAdminMode",
    }),

    ...mapActions("announcements", {
      updateAnnouncementGroupMember: "updateAnnouncementGroupMember",
      loadAnnouncements: "loadAnnouncements",
    }),

    ...mapActions("timeline", {
      loadTimeline: "loadTimeline",
      addTimelineEntry: "addTimelineEntry",
    }),

    goBack() {
      if (this.adminMode) {
        this.$router.push("/admin/announcements");
      }
    },

    handleNewChatEntry(newChatEntry) {
      let vm = this;
      let { entry } = newChatEntry;
      console.log("ANNOUNCEMENT HAS NEW CHAT ENTRY");
      console.log("Announcement ID local:");
      console.log(this.announcementId);
      console.log("Owner ID from message");
      console.log(entry.ownerId);
      if (this.announcementId == entry.ownerId) {
        vm.addTimelineEntry(entry);
        // alert("added inline!");
      }
    },

    listenToUpdateCurrentAnnouncementLastReadPointerInternalEvent() {
      let vm = this;
      eventBus.$on("update-current-announcement-last-read-pointer", () => {
        if (vm.justUpdatedMyLastReadPointer) {
          vm.justUpdatedMyLastReadPointer = false;
        } else {
          vm.startLastReadUpdater();
          vm.justUpdatedMyLastReadPointer = true;
        }
      });
    },

    getAnnouncementGroupKey(i) {
      return "announcement-group-" + i;
    },

    getAnnouncementChoiceButtonKey(i) {
      return "announcement-choice-button-" + i;
    },

    isChoiceSelected(choice) {
      if (this.myChoice) {
        return choice.id === this.myChoice.id;
      }
      return false;
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      announcementsApi
        .deleteAnnouncement(
          this.tenantId,
          this.announcementId,
          this.myPresenceId
        )
        .then(() => {
          vm.$message.success("Announcement deleted successfully");
          vm.loadAnnouncements();
          vm.$router.push("/announcements");
        })
        .catch((e) => {
          console.log(e);
          vm.isDeleting = false;
          vm.$message.error("Error deleting announcement");
        });
    },

    startLastReadUpdater() {
      let vm = this;
      vm.updateLastReadTries = 0;
      vm.lastReadUpdater = window.setInterval(function () {
        if (vm.updateLastReadTries > 3) {
          window.clearInterval(vm.lastReadUpdater);
          vm.lastReadUpdater = null;
          return false;
        }
        if (vm.myGroupMember) {
          vm.updateMyLastReadPointer();
          window.clearInterval(vm.lastReadUpdater);
          vm.lastReadUpdater = null;
        } else {
          vm.updateLastReadTries += 1;
        }
      }, 1000);
    },

    updateMyLastReadPointer() {
      // alert('update last read');
      let vm = this;
      vm.justUpdatedMyLastReadPointer = true;
      console.log(vm.myGroupMember);
      vm.updateAnnouncementGroupMember({
        announcementId: this.announcement.id,
        groupMember: {
          presenceId: vm.myGroupMember.presenceId,
          lastReadPointer: window.moment(),
        },
      });
      vm.updateServerLastReadPointer(
        vm.tenantId,
        vm.myPresenceId,
        vm.announcement.id
      );
    },

    subscribeIfAdminMode() {
      // Don't subscribe if I'm a group member
      if (this.myGroupMember && this.myGroupMember.isResponder) {
        return false;
      }
      try {
        this.$signalR.invoke("AddAnnouncementObserver", {
          TenantId: this.tenantId,
          GroupId: this.$route.params.id,
        });
      } catch (err) {
        console.log(err);
      }
    },

    unsubscribeIfAdminMode() {
      if (this.myGroupMember && this.myGroupMember.isResponder) {
        return false;
      }
      try {
        this.$signalR.invoke("RemoveObserver", {
          TenantId: this.tenantId,
          GroupId: this.$route.params.id,
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
  created() {
    // Check if is admin mode
    if (this.$route.query.admin == "true") {
      this.enableAdminMode();
    } else {
      this.disableAdminMode();
    }

    // this.listenToSignalRControlMessage();
    // this.listenToSignalRAnnouncementResponseMessage();
    this.listenToUpdateCurrentAnnouncementLastReadPointerInternalEvent();

    let idFromUrl = this.$route.params.id;
    let isThisAnnouncementLoaded =
      this.announcement && this.announcement.id === idFromUrl;
    if (!isThisAnnouncementLoaded) {
      this.loadAnnouncement({
        tenantId: this.tenantId,
        id: idFromUrl,
      });
    }
  },
  beforeDestroy() {
    if (this.adminMode) {
      this.unsubscribeIfAdminMode();
    }
  },
};
</script>

<style scoped lang="scss">
.announcement-tags-wrapper {
  margin-left: 10px;
}

.announcement {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;

  .announcement-top {
    flex-grow: 1;
    overflow-y: scroll;

    // No scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .announcement-bottom {
    flex-shrink: 1;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.loading-wrapper {
  margin-top: 50px;
}

.no-announcement-wrapper {
  margin-top: 50px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

// .announcement,
// .announcement-inner,
// #announcement-show-inner-layout {
//   height: 100%;
// }

#announcement-show-right-sidebar {
  box-shadow: 0 0 8px rgb(0 0 0 / 4%);
  z-index: 2;

  .ant-layout-sider-children {
    border-left: 1px solid #d9d9d9;
  }
}

.announcement-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .top {
    flex-shrink: 1;

    .page-header {
      margin-bottom: 0;

      .page-title {
        flex: none;
      }

      .actions {
        flex: 1;
        text-align: right;
        display: flex;
        justify-content: flex-end;
      }

      .name-input {
        max-width: 300px;
      }

      .header-inline-actions {
        margin-left: 10px;

        .ant-btn {
          margin-right: 5px;
        }
      }
    }
  }

  .middle {
    flex-grow: 1;
    overflow-y: scroll;

    // No scrollbar
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
  }

  .bottom {
    padding-top: 15px;
    flex-shrink: 1;
  }
}

.announcement-description {
  margin-bottom: 40px;
}

.responder-wrapper {
  // margin-bottom: 40px;
  h2 {
    font-size: 15px;
    margin-bottom: 10px;
  }
}

.announcement-response-card {
  margin-bottom: 15px;
}
</style>